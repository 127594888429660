import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import SEO from './seo'

const layoutStyle = {
  margin: `0 auto`,
  padding: `1.5rem 1.125rem`,
  maxWidth: `42rem`,
}

const Layout = ({ children }) => {
  console.log('🧁')

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={layoutStyle}>
      <SEO />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
